/* src/Layout.css */
body {
    margin: 0;
    padding: 0;
    font-family: 'Arial', sans-serif;
  }
  
  header {
    background-color: #8db596; /* Soft pastel green for the header */
    color: #ffffff; /* White text for contrast */
    padding: 20px 0; /* Padding for some spacing */
    text-align: center; /* Center the header content */
    font-size: 24px; /* Larger font size for the header text */
  }
  
  footer {
    background-color: #8db596; /* Matching the header with the same pastel green */
    color: #ffffff; /* White text for contrast */
    padding: 10px 0; /* Slightly less padding than the header */
    text-align: center; /* Center the footer content */
    bottom: 0;
    left: 0;
    width: 100%; /* Ensure the footer stretches across the bottom */
    font-size: 18px; /* Slightly smaller font size than the header */
  }
  
  main {
    padding: 20px;
    padding-bottom: 60px; /* Add padding to the bottom to ensure content isn't hidden by the footer */
    box-sizing: border-box;
  }
  