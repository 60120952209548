/* src/HomePage.css */
.jobs-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    padding: 20px;
  }
  
  .job-card {
    background-color: #fdfd96;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    padding: 15px;
    transition: box-shadow 0.2s ease;
  }
  
  .job-card:hover {
    box-shadow: 0 5px 15px rgba(0,0,0,0.2);
  }
  
  .job-title {
    color: #305926;
    font-size: 18px;
    font-weight: bold;
  }
  
  .job-details {
    font-size: 14px;
    color: #305926;
    margin-top: 10px;
  }
  